import React, { useState } from "react";
import Phone from "./Phone";
import FadeIn from "./FadeIn";
import snap from "../images/snap.svg";
import {
  Grid,
  Container,
  Typography,
  Button,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ShopIcon from "@mui/icons-material/Shop";
import AppleIcon from "@mui/icons-material/Apple";
//import peace from "../images/peace.svg";
import playstore from "../images/playstore.svg";
import appstore from "../images/appstore.svg";
import stet from "../images/stethoscope.svg";
// import send from "../images/send.svg";
import review from "../images/review.svg";
import { PredictionBody } from "./PredictionBody";
//import logo from "../images/Dermalens1.png";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export const useStyles = makeStyles((theme) => ({
  content1: {
    fontSize: "1.2rem",
    lineHeight: "1.6",
    color: "#333",
  },
  patented: {
    color: "white", 
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "none", 
    padding: "5px 10px", 
    border: "2px solid white", 
    borderRadius: "8px", 
    transition: "all 0.3s ease-in-out", 
   
   
  },
  dialogTitle: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
  download: {
    paddingTop: "5.5vh",
    background: "#3F67F6",
    paddingBottom: "5.5vh",
  },
  downloadContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "40px",
  },
  head: {
    color: "#FFFFFF",
    fontSize: "3em",
    fontWeight: 400,
    marginBottom: "0",
    [theme.breakpoints.down('md')]: {
      textAlign:"center"
    },
  },
  derma: {
    fontWeight: "700",
    
  },
  infoContainer: {
    padding: "10% 80px 10% 20px",
    [theme.breakpoints.down('md')]: {
      padding: "10%",
    },
  },
  content: {
    fontFamily: "Nunito Sans",
    fontWeight: "500",
    color: "#FFFFFF",
    fontSize: "1.5em",
    padding: "5px",
    margin: "0 !important",
    lineHeight: "1.6",
    position: "relative",
    display: "inline-block",
    [theme.breakpoints.down('md')]: {
      textAlign:"center",
      fontSize: "1.25rem",
    },
  },
  byContent: {
    margin: "0",
  },
  content11: {
    marginTop: "30px",
    fontFamily: "Nunito Sans",
    fontWeight: "500",
    color: "#FFFFFF",
    fontSize: "1.5em",
    padding: "5px",
    [theme.breakpoints.down('md')]: {
      textAlign:"center",
      fontSize: "1.35rem",
    },
  },
  content2: {
    
  },

  button: {
    borderRadius: 40,
    width: "90%",
    textTransform: "none",
    fontSize: "18px",
    background: "#3F67F6",
    margin: theme.spacing(1),
    boxShadow: "none",
    border: "3px solid #FFFFFF",
    "&:hover": {
      background: "#0641b6",
      color: "#FFFFFF"
    },
  },

  button0: {
    color: "#3F67F6",
    background: "#FFFFFF",
  },
  download_button: {
    borderRadius: 10,
    // height: "50px",
    width: "70%",
    border: 0,
    transition: "0.2s",
    "&:hover": {
      transform: "translate(0px, -10px)",
      background: "#3F67F6",
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: "15%",
    },
  },
  center: {
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: "25px",
  },
}));
function StartNow() {
  return (
    <div>
      <br />
      Get your skin issues resolved !
      <br />
      Start Now!
      <List component="nav" aria-label="click crop upload detect">
        <ListItem button>
          <ListItemIcon>
            <img width="35" height="35" src={snap} alt="Skin detect Click Sinzy Dermalens" />
          </ListItemIcon>
          <ListItemText primary="Click" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <img width="35" height="35" src={review} alt="Aim4u AIm4U Skin Detect DermaLens" />
          </ListItemIcon>
          <ListItemText primary="Detect" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <img width="35" height="35" src={stet} alt="Consult" />
          </ListItemIcon>
          <ListItemText primary="Consult" />
        </ListItem>
        <br />
        Get your skin review.
      </List>
    </div>
  );
}

function Intro({ classes }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Typography sx={{marginBottom: "0"}} className={classes.content} gutterBottom>
          <i className={classes.byContent}>- By AiM4u Software Solutions </i>
      </Typography>
      <div>
      {/* Main Typography */}
      <Typography className={classes.content11} variant="h5" gutterBottom>
       
          A <span className={classes.patented} onClick={handleOpen}>Patented</span> AI and Deep Learning-powered
          Skincare Assistant that analyzes your skin concerns and provides instant access to dermatologists
          anytime, anywhere.
        
      </Typography>

      {/* Modal Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle className={classes.dialogTitle}>Patented Technology Details</DialogTitle>
        <DialogContent>
         
         
          <Typography variant="body1" gutterBottom>
            <strong>Patented Technology:</strong>  
          </Typography>
          <ul>
            <li>Utility Model - German Patent</li>
            <li>Grant No.: 20 2024 100 262.8</li>
            <li>Dated: 26th February 2024</li>
          </ul>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
      <Grid className={classes.buttonContainer} container>
        <Grid className={classes.center} item sm={6} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={`${classes.button} ${classes.button0}`}
            href="#features"
          >
            <b>Features</b>
          </Button>
        </Grid>
        <Grid className={classes.center} item sm={6} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            href="#download"
          >
            Get it Now
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
function GetNow({ classes }) {
  return (
    <div id="download" className={classes.downloadContainer}>
      <Typography className={classes.content} variant="h5" gutterBottom>
        <div>Get the app to start your skin journey now!</div>
      </Typography>
      <Typography  className={`${classes.content} ${classes.content2}`} variant="h5" gutterBottom>
        <div>Available on Android and IOS  </div>
      </Typography>
      <Grid className={classes.buttonContainer} container>
        {/*<Grid className={classes.center} item sm={6} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            href="https://play.google.com/store/apps/details?id=com.skinzy.dermalens"
            startIcon={<ShopIcon style={{ fontSize: "25px" }} />}
          >
            Google Play
          </Button>
        </Grid>
        
        <Grid className={classes.center} item sm={6} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            href="https://apps.apple.com/us/app/dermalens/id1534709519"
            startIcon={<AppleIcon style={{ fontSize: "25px" }} />}
          >
            Apple Store
          </Button>
        </Grid> */}
        <Grid item sm={6} xs={12}>
          <div>
            <a
              color="primary"
              aria-label="playstore"
              href="https://play.google.com/store/apps/details?id=com.aim4u.dermalens"

            >
              <img
                src={playstore}
                className={`${classes.button} ${classes.download_button}`}
                alt="Aim4u playstore App DermaLens"
              />
            </a>
          </div>
        </Grid>
       <Grid item sm={6} xs={12}>
          <div>
            {/* <a
              color="primary"
              aria-label="appstore"
              href="https://apps.apple.com/us/app/dermalens/id1534709519"
            >
              <img
                src={appstore}
                className={`${classes.button} ${classes.download_button}`}
                alt="Aim4u App store Dermalens"
              />
            </a> */}
           
              <img
                src={appstore}
                className={`${classes.button} ${classes.download_button}`}
                alt="Aim4u App store Dermalens"
              />
          
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default function Download({ bottom = undefined }) {
  const classes = useStyles();

  return (
    <Grid className={classes.download} container>
      <Grid item xs={12} sm={12} md={6}>
        <FadeIn>
          <Phone />
        </FadeIn>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <FadeIn>
          <Container className={classes.infoContainer}>
            <h2 className={classes.head}>
              <b className={classes.derma}>DERMA</b>LENS
            </h2>
            {bottom === undefined ? (
              <Intro classes={classes} />
            ) : (
              <GetNow classes={classes} />
            )}

          </Container>
        </FadeIn>
      </Grid>
    </Grid>
  );
}
