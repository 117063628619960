import React, { useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  
} from 'chart.js';

import {
  Box,
  // Container,
  Button,
  // Card,
  // CardHeader,
  // CardContent,
  Typography,
  // CardActions,
} from "@mui/material";
import FadeIn from "./FadeIn";
import { ReactComponent as Consult } from "../images/consult.svg";
import { ReactComponent as Quiz } from "../images/quiz.svg";
import { ReactComponent as Search } from "../images/search.svg";
import { ReactComponent as Distype } from "../images/type.svg";
// import { PredictionModal } from "./PredictionModal";
import { DiseaseModal } from "./DiseaseModal";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "@mui/material/Modal";
import Intern from '../images/Internship.png'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
//import Dry from '../images/Dry result1.jpg'
//import Oily from '../images/Oily skin having acne (1).jpg'
export const useStyles = makeStyles((theme) => ({
  container: {
    background: "#cfd9fd",
    // background:"blue",
    padding: "60px 40px",
  },
  block_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "10px",
  },
  button: {
    width: "200px",
    
    borderRadius: "30px",
    background: "#3F67F6",
    fontSize: "20px",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    "&:hover": {
      background: "#0641b6",
      color: "white",
    },
  },
  block_head: {
    paddingTop: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
  },
  block_text: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 400,
    // height: "180px",
  },
  block_icon: {
    height: "150px",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "10px",
    height: "445px",
    width: "236px",
    margin: "15px 0 25px",
  },
  featurePreview: {
    textAlign: "center",
    color: "#555555",
    fontSize: "17px",
    padding: "20px",
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%', // Make the modal responsive
  maxWidth: '600px', // Restrict the maximum width
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh', 
  overflowY: 'auto', // Add scroll for overflow content
};
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const FeatPrev = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [disOpen, setDisOpen] = React.useState(false);
  const dishandleOpen = () => setDisOpen(true);
  const dishandleClose = () => setDisOpen(false);

  
  const classes = useStyles();

  const Consultbox = () => {
    return (
      <div className={classes.block}>
        <Consult className={classes.block_icon} />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Get Your Skin Issue Resolved
        </Typography>
        {/* <Typography variant="body" gutterBottom className={classes.block_text}>
          Get an online consultation about your skin from our selection of
          certified doctors.
        </Typography> */}
         <Typography variant="body" gutterBottom className={classes.block_text}>
          Download the DermaLens App 
        </Typography>
        <Button className={classes.button} href="#download">
          Try Now
        </Button>
      </div>
    );
  };

  const Quizbox = () => {
    return (
      <div className={classes.block}>
        <Quiz className={classes.block_icon} />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Test Your Knowledge
        </Typography>
        <Typography variant="body" gutterBottom className={classes.block_text}>
          {/* Are you a dermatology student? Test your identification skills with
          our technology. */}
          Are you a Student of BioTech/Medical/Science/General?
        </Typography>
        
        <Button
          className={classes.button}
          href="https://quiz.aim4u.co.in/"
          target="_blank"
        >
          Test your Skills
        </Button>
      </div>
    );
  };
// {"oily": "0.97817034", "dry": "0.021829687"}
  const Typebox = () => {
    const [file, setFile] = useState(null);
     const [imagePreview, setImagePreview] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
     const selectedFile = event.target.files[0];
    setFile(selectedFile);

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'https://pfg1plqbgd.execute-api.ap-south-1.amazonaws.com/default/oily-dry-prediction',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      
      // Add the new result to the results array
      results.pop();
      setResults((prevResults) => [...prevResults, response.data]);
    } catch (err) {
      if (err.response) {
        console.error("API Response Error: ", err.response);
        setError(`Error: ${err.response.data.message || err.response.statusText}`);
      } else if (err.request) {
        console.error("No response received: ", err.request);
        setError('No response from the server.');
      } else {
        console.error("Error in request setup: ", err.message);
        setError(`Request Error: ${err.message}`);
      }
    } finally {
      setLoading(false);
         
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result); 
      };
      reader.readAsDataURL(file); 
    }
      
    }
  };

  // Prepare data for the bar chart
  const chartData = {
    labels: results.map((_, index) => `Result ${index + 1}`), 
    datasets: [
      {
        label: 'Oily',
        data: results.map((result) => parseFloat(result.oily)), 
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dry',
        data: results.map((result) => parseFloat(result.dry)), 
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Oily vs Dry Predictions',
      },
    },
  };

  return (
    <div className={classes.block}>
    <Distype className={classes.block_icon} />
    <Typography variant="h5" gutterBottom className={classes.block_head}>
      Know your skin type
    </Typography>
    <Typography variant="body" gutterBottom className={classes.block_text}>
 
      Oily and Dry Skin Prediction
    </Typography>
    <p > <b>This innovation is patented as Utility Model German Patent<br>
    </br> Grant No.20 2024 100 262.8 dated 26th Feb 2024</b></p> <br></br>
    <Button className={classes.button} onClick={handleOpen}>
      Our Model Results
    </Button>
   
    
    {/* <PredictionModal
      open={open}
      handleClose={handleClose}
      detect={"skin"}
    /> */}


    {/* Oily and Dry Prediction */}
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={{ ...style, position: 'relative' }}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
        top: 16, 
        right: 16, 
        zIndex: 1300, 
        backgroundColor: 'white', 
        borderRadius: '50%',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',

          fontWeight: 'bold',
        }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <br></br><br />
        <Typography id="modal-modal-title" variant="h6" component="h2">
        Our AI Model validation accuracy of 98% for oily and dry skin classification
        </Typography>
        
<br /> <br />

        <div>

  <form onSubmit={handleSubmit}>
    <input type="file" onChange={handleFileChange}  />
    <button type="submit" disabled={loading} className={classes.button}>
      {loading ? 'Uploading...' : 'Upload and Predict'}
    </button>
  </form>
  <br /><br />
  {error && <p style={{ color: 'red' }}>{error}</p>}
  {imagePreview && (
        <div>
          <h3>Image Preview:</h3>
          <img src={imagePreview} alt="Preview" style={{ maxWidth: "50%", height: "auto",marginLeft:'30%' }} />
        </div>
      )}
  <br /><br />
  <div>
   
    <Bar data={chartData} options={chartOptions} />
  </div>
</div>
   

      </Box>
    </Modal>
  </div>
  );
  };

  const Searchbox = () => {
    const classes = useStyles();
    return (
      <div className={classes.block}>
        <Search className={classes.block_icon} />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Detect Your Disease
        </Typography>
        <Typography variant="body" gutterBottom className={classes.block_text}>
          Not sure about your skin infection? Identify your disease by clicking
          a picture.
        </Typography>
        <Button className={classes.button} onClick={dishandleOpen}>
          Try Now
        </Button>
        <DiseaseModal
          open={disOpen}
          handleClose={dishandleClose}
          detect={"skin"}
        />
        <Button onClick={handleOpen}>Open modal</Button>
      </div>
    );
  };


  const Gyan = () => {
    return (
      <div className={classes.block}>
        {/* <Quiz className={classes.block_icon} /> */}
        <img
        src={Intern}
        alt="Intern working on a project"
        className={classes.block_icon}
        />
        <Typography variant="h5" gutterBottom className={classes.block_head}>
          Apply for Internship
        </Typography>
        <Typography variant="body" gutterBottom className={classes.block_text}>
        Elevate your skills to the next level
          
        </Typography>
       
        <Button className={classes.button} href="https://gyan.aim4u.co.in/"
          target="_blank">
          Visit Gyan
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container} id="features">
      <div className={classes.featurePreview}>
        <FadeIn>
          <h1>FEATURE PREVIEW</h1>
        </FadeIn>
      </div>
      <FadeIn>
        <div className={classes.block_container}>
          <Typebox />
          <Quizbox />
          {/* <Searchbox /> */}
          <Consultbox />
          <Gyan />
        </div>
       
       

      </FadeIn>
    </div>
  );
};

export default FeatPrev;
